import { useAppDispatch, useAppSelector } from '../../../store/redux.hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { applicationType, getNotificationListing } from 'store/commonData/commonSlice';
import { getUserDetails } from 'store/user/userSlice';
import { getAdminUrl, getBaseUrl } from "utils";

/**
 * 
 * @returns All the states and functions for DashboardView
 */

const HeaderController = () => {
    // Define constant 
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {isSidebarOpen, applicationTypeStatus, pageTitle, isLoadingNotification, notificationDetail } = useAppSelector((state: any) => state.commonData)
    const { userProfile } = useAppSelector((state: any) => state.user)
    const [notification, setNotification] = useState<boolean>(false)
    const { loginDetails } = useAppSelector((state: any) => state.auth);

    const handleClose = () => {
        setNotification((prev: boolean) => !prev);
    };

    useEffect(() => {
        dispatch(getUserDetails())
        dispatch(getNotificationListing())
    }, [dispatch])
    useEffect(() => {
        if (notification) {
            dispatch(getNotificationListing())
        }
    }, [dispatch, notification])
    const handleToggleApplication = (e: any) => {
        localStorage.setItem("applicationType", e)
        dispatch(applicationType(e))
        navigate(!e ? getBaseUrl(loginDetails?.data) : getAdminUrl(loginDetails?.data?.permissionsData || []))
    }

    //  All the states and functions returned
    return {
        userProfile,
        notification,
        pageTitle,
        loginDetails,
        applicationTypeStatus,
        handleToggleApplication,
        isLoadingNotification,
        notificationDetail,
        handleClose,
        dispatch,
        isSidebarOpen
    };
};

export default HeaderController;