export const routeKey = {
    Visibility: "VIS",
    Recommendations: "REC",
    Manage: "MAN",
    Segmentation: "SEG",
    Benchmarks: "BEN",
    UserManagement: "USM",
    RoleManagement: "USM",
    DataManagement: "DAM",
    ApplicationManagement: "APM",
    AlternativeFuel: "AMS",
    ModalShift: "AMS",
    CarrierShift: "CAS",
    AdministratorAccess: "ADA",
    ApplicationAccess: "APA",
    EvDashboard: "EVD",
    BidPlanning: "BIP"
}