import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/redux.hooks';
import { logoutPost } from 'store/auth/authDataSlice';
import { useAuth } from 'auth/ProtectedRoute';
import { decryptDataFunction } from 'utils';
import cookie from 'react-cookies'
import { domainUrl } from 'constant'
/**
 * 
 * @returns All the states and functions for DashboardView
 */

const SidebarController = () => {
    // Define constant 
    const location = useLocation();
    const dispatch = useAppDispatch()
    const {isSidebarOpen, applicationTypeStatus } = useAppSelector((state: any) => state.commonData)
    const { loginDetails, userPassword } = useAppSelector((state: any) => state.auth)

    const navigate = useNavigate()

    // Logout function
    const handleLogout = () => {
        dispatch(logoutPost() as any);
        navigate("/")
    }
    const dataCheck = useAuth();

    const handleNavigation = () => {
        cookie.save('ev_user', { email: loginDetails?.data?.email, password: decryptDataFunction(userPassword), loginDetailToken: loginDetails?.data?.token }, { path: '/', domain: domainUrl })
    }


    //  All the states and functions returned
    return {
        dataCheck,
        handleLogout,
        applicationTypeStatus,
        location,
        loginDetails,
        handleNavigation,
        isSidebarOpen,
        dispatch
    };
};

export default SidebarController;