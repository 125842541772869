import axios from 'axios'
import { getLocalStorage, setRegionID } from 'utils';
import { actUrl } from "constant"

// Login API Call
const authLoginPost = async (userData: any): Promise<ApiResponse> => {
  try {
    const response: ApiResponse = (await axios.post(`${actUrl}login-user-access`, userData)).data;

    // If login is successful, save login details and set token in axios headers
    if (response?.status && !(response?.data?.otp)) {
      localStorage.setItem("loginDetails", JSON.stringify(response?.data));
      setRegionID(response?.data?.region_id)
    }
    return response;

  } catch (error: any) {
    throw error;
  }
};

// OTP verify API call
const authPostOtp = async (userData: any): Promise<ApiResponse> => {
  try {
    const response: ApiResponse = (await axios.post(`${actUrl}verifyOTP`, userData)).data;
    localStorage.setItem("loginDetails", JSON.stringify(response?.data));
    setRegionID(response?.data?.region_id)
    return response;
  } catch (error: any) {
    throw error;
  }
};

const bucketLoginPost = async (userData: any): Promise<ApiResponse> => {
  try {
    const response: ApiResponse = (await axios.post(`${actUrl}blob-login`, userData)).data;
    // If login is successful, save login details and set token in axios headers
    if (response?.status) {
      localStorage.setItem("bucketLoginDetails", JSON.stringify(response?.data));
    }
    return response;
  } catch (error: any) {
    throw error;
  }
};

// Resend OTP API call
const resendPostOtp = async (userData: any): Promise<ApiResponse> => {
  try {
    return (await axios.post(`${actUrl}resendOtp`, userData)).data;
  } catch (error: any) {
    throw error;
  }
};


// Logout API call
const logOutApi = async (): Promise<ApiResponse> => {
  try {
    return (await axios.get(`${actUrl}logout`)).data;
  } catch (error: any) {
    throw error;
  }
};


const bucketUploadFile = async (userData: any) => {
  try {
    let tokenData: any = getLocalStorage("bucketLoginDetails")
    const file = userData?.ref;
    return await axios({
      method: 'put',
      url: `${tokenData?.blobToken?.url}/${userData?.ref?.name}?${tokenData?.blobToken?.sasToken}`,
      data: file,
      headers: {
        "Content-Type": "application/octet-stream",
        Accept: "application/json, text/plain, */*",
        "Accept-Language": "en-GB,en-US;q=0.9,en;q=0.8",
        "Cache-Control": "no-cache",
        Connection: "keep-alive",
        isOpenedKey: true,

        Origin: "https://appdev.greensight.ai",
        Pragma: "no-cache",
        "Sec-Fetch-Dest": "empty",
        "Sec-Fetch-Mode": "cors",
        "Sec-Fetch-Site": "cross-site",
        "User-Agent":
          "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36",
        deniedCancle: "true",
        "file-name": file?.name,
        "file-type": file?.type,
        "sec-ch-ua":
          '"Not_A Brand";v="8", "Chromium";v="120", "Google Chrome";v="120"',
        "sec-ch-ua-mobile": "?0",
        "sec-ch-ua-platform": '"Linux"',
        "x-ms-blob-type": "BlockBlob",
        "x-ms-date": new Date().toUTCString(),
        "x-ms-version": "2023-08-03",
      },
      onUploadProgress: userData?.progressFn
    })
  }
  catch (err) {
  }
}
const authService = {
  authLoginPost,
  authPostOtp,
  resendPostOtp,
  logOutApi,
  bucketLoginPost,
  bucketUploadFile
};

export default authService;
