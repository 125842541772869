import axios from "axios";
import { actUrl } from "constant"


/**
 * Regional Services
 */

// Function to fetch region table data
const regionTableDataGet = async (userData: any) => {
    try {
        const response = await axios.post(`${actUrl}get-region-table-data`, userData);

        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

// Function to fetch region graph data
const regionGraphPost = async (userData: any) => {
    try {
        const response = await axios.post(`${actUrl}get-region-emission-data`, userData);

        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

// Function to fetch region quarterly data
const regionQuartelyGet = async (userData: any) => {
    try {
        const response = await axios.post(`${actUrl}get-region-intensity-quarterly`, userData);

        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

// Function to fetch facility emission data
const regionFacilityEmissionApi = async (userData: any) => {
    try {
        const response = await axios.post(`${actUrl}get-facilities-emission-data`, userData);

        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

// Object containing all regional services
const regionService = {
    regionTableDataGet,
    regionGraphPost,
    regionQuartelyGet,
    regionFacilityEmissionApi
};

// Export the regional service object
export default regionService;
