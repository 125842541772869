export enum valueConstant {
DATE_FORMAT="MM-DD-YYYY",
STATUS_ACTIVE=1,
STATUS_DEACTIVATE=2
}

export const evProductCode = "EV"
export const rdProductCode = "RD"

export const instructions = [
    {
        fileicon: "/images/xlsFile.svg",
        text: "Only excel files are accepted for upload. Ensure that your file has the '.xlsx' extension.",
    },
    {
        fileicon: "/images/xlsFile.svg",
        text: "Each field in your excel file should be labeled appropriately to indicate the type of data it contains.",
    },
    {
        fileicon: "/images/fileListIcon.svg",
        text: "Remove any unnecessary data.",
    },
    {
        fileicon: "/images/fileListIcon.svg",
        text: "All data should be in correct format.",
    },
    {
        fileicon: "/images/toDo.svg",
        text: " Required fields: Origin City, Origin State, Destination City, Destination State, SCAC, and RPM.",
    }
];