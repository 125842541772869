import Heading from "component/heading";
import ImageComponent from "component/images"
import ChatbotController from "./chatbotController";
import { useEffect, useRef } from "react";
import ButtonComponent from "component/forms/button";
import { ThreeDots } from "react-loader-spinner";
import { Col, Row } from "reactstrap";

const formatText = (text: any) => {
    // Replace new lines (\n) with <br /> tags
    const withLineBreaks = text.split('\n').join('<br />');

    // Replace **bold** with <strong>bold</strong>
    const withBoldText = withLineBreaks.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');


    return withBoldText;
};


const Chatbot = () => {
    const {
        toggleChatBox,
        isChatBoxOpen,
        isEditVisible,
        selectedType,
        messages,
        handleChange,
        sendMessage,
        isLoading,
        messagesList,
        cardClick,
        onKeyDownHandler,
        divRef,
    } = ChatbotController()

    const useAutosizeTextArea = (
        textAreaRef: HTMLTextAreaElement | null,
        messages: string
    ) => {
        useEffect(() => {
            if (textAreaRef) {
                textAreaRef.style.height = "0px";
                const scrollHeight = textAreaRef.scrollHeight;
                textAreaRef.style.height = scrollHeight + "px";

            }
        }, [textAreaRef, messages]);
    };
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    useAutosizeTextArea(textAreaRef.current, messages);

    const sectionData = [
        {
            questionId: "selectRegion", title: "", image: "/images/sidebar/trackIcon.svg", isDisabled: false, heading: "Track", type: "track", paragraph: "Segmented data for emissions"
        },
        {
            title: "Coming Soon", image: "/images/sidebar/benchmarksIcon.svg", isDisabled: true, heading: "Benchmarks", type: "benchmarks", paragraph: "Lorem Ipsum is simply dummy text"
        },
        {
            title: "Coming Soon", image: "/images/sidebar/act.svg", heading: "Act", isDisabled: true, type: "act", paragraph: "Lorem Ipsum is simply dummy text"
        },
        {
            title: "Coming Soon", image: "/images/sidebar/manageIcon.svg", heading: "Projects", isDisabled: true, type: "projects", paragraph: "Lorem Ipsum is simply dummy text",
        },
    ]
    return (
        <div className="chatbot-outer-screen">
            <div>
                <div className="chatbot-tooltip-wrap">
                    <ButtonComponent imagePath={isChatBoxOpen ? "/images/chatbot/cross.svg" : "/images/chatbot/chatbot.svg"} onClick={toggleChatBox} btnClass={`chatbot-ai ${isChatBoxOpen ? "cross-icon" : "chatbot-icon"}`} />
                   {!isChatBoxOpen && <div className="chatbot-tooltip">
                        <Heading className="font-14 fw-medium mb-0" level="2" content="Hello! I'm GreenSight AI, your assistant." />
                    </div>}
                </div>


                {isChatBoxOpen && (<div className="chatbot-screen">
                    <div className="header d-flex justify-content-between align-items-center gap-2">
                        <div className="d-flex align-items-center gap-3">
                            <ImageComponent path="/images/chatbot/stars.svg" className="pe-0" />
                            <Heading level="3" content="AI Assistant " className="font-xxl-18 font-16 fw-medium mb-0" />
                        </div>
                        <ButtonComponent imagePath="/images/chatbot/cross.svg" btnClass="pe-0 btn-transparent cross-header" onClick={toggleChatBox} />
                    </div>
                    <div className="chatbot-inner-section">

                        {!selectedType ? <Row className="align-items-center justify-content-center">
                            <Col lg="8"> <div className="main-text px-2 flex-column">
                                <Heading className="font-18 fw-medium mb-2" level="2" content="Hello! I'm GreenSight AI, your decarbonization assistant." />
                                <Heading className="font-16 fw-medium mb-4 gray-txt" level="2" content="Please select a workflow." />
                                <Row className="w-100 g-3">
                                    {
                                        sectionData.map((cardData) => (
                                            <Col sm="6" key={cardData.heading}>
                                                <button type="button" disabled={cardData.isDisabled} className={`chatbot-cards p-2 text-start h-100 ${cardData.isDisabled ? "disabled " : " "}`} onClick={() => cardClick(cardData)}>
                                                    <ImageComponent path={cardData.image} className="pe-0 mb-1" />
                                                    <Heading level="3" className="font-14 fw-medium mb-2" content={cardData.heading} />
                                                    <p className="fw-normal font-12 mb-1">{cardData.paragraph}</p>
                                                </button>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </div>
                            </Col>
                        </Row> : (
                            <div className="chatbot-message-section" >
                                {messagesList?.map((item: any) => (
                                    <>{
                                        item?.isUser ? (
                                            <div className="sender d-flex justify-content-end mb-2 gap-1">

                                                <div>
                                                    <div className="sender-wrapper">
                                                        <div dangerouslySetInnerHTML={{ __html: formatText(item?.message) }} />
                                                    </div>
                                                    {isEditVisible && (<div className="d-flex justify-content-end gap-2 mt-2">
                                                        <ButtonComponent text="Cancel" btnClass="gray-btn" />
                                                        <ButtonComponent text="Save" btnClass="btn-green" />
                                                    </div>)}
                                                </div>

                                            </div>
                                        ) : (
                                            <div className="chatbot d-flex justify-content-start gap-2 align-items-end mb-2">
                                                <ImageComponent path="/images/chatbot/chatbot-ai.svg" className="pe-0 mb-0" />

                                                <div >
                                                    <div className="chatbotai-wrapper">

                                                        <Heading level="4" className="font-12 mb-0 fw-normal">
                                                            <div dangerouslySetInnerHTML={{ __html: formatText(item?.message) }} />

                                                        </Heading>
                                                    </div>
                                                    <div className="d-flex gap-1 flex-wrap ">
                                                        {item?.isOption && item?.optionDto?.list?.map((res: any) => (
                                                            <div className="d-flex gap-2" key={res.id}>
                                                                <button
                                                                    type="button"
                                                                    disabled={item.isDisable}
                                                                    className={item.isDisable ? "btn-gray" : "btn-green"}
                                                                    onClick={() => {
                                                                        item?.optionDto?.handleClick(res, item)
                                                                    }
                                                                    }
                                                                >
                                                                    {res?.name}
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <Heading level="6" className="font-xxl-12 font-10 mb-0 fw-normal mt-1">GreenSight AI Bot</Heading>
                                                </div>
                                            </div>
                                        )
                                    }
                                    </>
                                )
                                )}
                                {isLoading &&
                                    <div className="chatbot d-flex justify-content-start gap-2 align-items-end mb-3">
                                        <ImageComponent path="/images/chatbot/chatbot-ai.svg" className="pe-0 mb-3" />
                                        <div className="mb-2">
                                            <ThreeDots
                                                visible={true}
                                                height="40"
                                                width="40"
                                                color="#B6B3B3"
                                                radius="9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                            />
                                        </div>
                                    </div>
                                }
                                <div ref={divRef} />
                            </div>)}
                        {selectedType &&

                            <div className="text-area-wrapper">
                                <form>
                                    <textarea
                                        id="review-text"
                                        onChange={handleChange}
                                        placeholder="Enter your message"
                                        ref={textAreaRef}
                                        rows={1}
                                        onKeyDown={onKeyDownHandler}
                                        value={messages}
                                        className="text-area"
                                    />
                                    <ButtonComponent disabled={isLoading} onClick={sendMessage} imagePath={isLoading? "/images/chatbot/disabled-send-msg.svg" : "/images/chatbot/send-msg.svg"} btnClass="btn-transparent p-0" />
                                </form>
                            </div>


                        }
                    </div>
                </div>)}
            </div>
        </div >
    )
}

export default Chatbot