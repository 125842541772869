import {valueConstant} from "constant/valueConstant";
import moment from "moment";

// DateTimeShow component to display the last update date and time
const DateShow = ({ dateInfo, isActive }: any) => {
  return (
    <>
      {isActive ? (
        dateInfo?.data?.emission_dates && (
          <div className="lates-update">
            <p className="d-flex align-items-center mb-0 justify-content-start font-14">
              Data available from{" "}
              {moment
                .utc(dateInfo?.data?.emission_dates?.start_date)
                .format(valueConstant?.DATE_FORMAT)}{" "}
              to{" "}
              {moment
                .utc(dateInfo?.data?.emission_dates?.end_date)
                .format(valueConstant?.DATE_FORMAT)}
            </p>
          </div>
        )
      ) : (
        <>
          {/*  Paragraph to display the last update date and time */}
          <p className="font-12 mb-1 d-flex align-items-center">
            {/* Display the last updated time in "hh:mm A" format */}
            Last updated at {moment().format("hh:mm A")} on{" "}
            {/* Display the last updated date in "DD MMM YY" format */}
            {moment().format(valueConstant?.DATE_FORMAT)}
            {/* Display the LatestUpdate icon */}
           
          </p>
        </>
      )}
    </>
  );
};

DateShow.defaultProps = {
  isActive: false,
  dateInfo: null,
};

export default DateShow;
