// Import necessary assets
import moment from "moment";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { companySlug, listCode, pageSizeList, regionList, monthOption, actUrl } from "constant";
import { imageToken, imageURL } from "./InterceptorApi";
import { routeKey } from "constant/routeKey";
import styles from 'scss/config/_variable.module.scss';
import { evProductCode } from "constant/valueConstant";

const CryptoJS = require("crypto-js");

export const kmToMilesConst = 0.621371;
export const currentYear = 2024;


// Define interfaces for Contributor and Detractor data
interface ContributorItem {
  name: string;
  value: number;
  color: string;
}

interface DetractorItem {
  name: string;
  value: number;
  color: string;
}

// Define a union type for GraphDataItem
type GraphDataItem = ContributorItem | DetractorItem;

interface Contributor {
  name: string;
  value: number;
  color: string;
}

interface Detractor {
  name: string;
  value: number;
  color: string;
}

//Get value from local storage or default to an empty string
export const getLocalStorage = (key: string) => {
  const localStorageData = localStorage.getItem(key);
  if (!localStorageData) {
    return "";
  }
  return JSON.parse(localStorageData);
};


// Function to handle error responses
export const getErrorMessage = (error: AxiosError): any => {
  const errorData: any = error?.response?.data;
  const errorMessage = errorData?.message.toString() || error.message.toString() || "Something went wrong!";
  errorData && toast.error(errorMessage);
  return { code: error?.code, message: errorMessage };
};

// Helper function to get defalut url
export const getBaseUrl = (loginDetails: any) => {
  if (checkRolePermission(loginDetails, "regionalManager")) {
    return "/regional-level";
  } else {
    return "/sustainable";
  }
};

// Function to generate a list of years based on date range
export const yearList = (dto: { start_date?: Date; end_date?: Date; }) => {
  let a: number[] = [];

  const startDate = dto?.start_date ? moment(dto.start_date) : moment();
  const endDate = dto?.end_date ? moment(dto.end_date) : moment();

  for (let i = startDate.year(); i <= endDate.year(); i++) {
    a.push(i);
  }
  return a;
};

// Function to generate a list of quarters based on the current year and selected year
export const getQuarters = (yearlyData: string | number) => {
  const latestYear = new Date().getFullYear();
  const quarter = Math.floor((new Date().getMonth() + 3) / 3);

  let list: { value: string | number; name: string; }[] = [
    { value: 0, name: quarter <= 4 ? "YTD" : "All" },
    { value: 1, name: "Q1" },
  ];

  // Add quarters based on current year and selected year
  if (Number.parseInt(yearlyData?.toString(), 10) >= latestYear) {
    if (quarter >= 2) {
      list.push({ value: 2, name: "Q2" });
    }
    if (quarter >= 3) {
      list.push({ value: 3, name: "Q3" });
    }
    if (quarter >= 4) {
      list.push({ value: 4, name: "Q4" });
    }
  } else {
    list = [
      { value: 0, name: "All" },
      { value: 1, name: "Q1" },
      { value: 2, name: "Q2" },
      { value: 3, name: "Q3" },
      { value: 4, name: "Q4" },
    ];
  }
  return list;
};

// Function to get the name of a quarter based on its numeric value and year

export const isCompanyEnable = (userDetail: any, companyList: any): boolean => {
  return companyList.includes(userDetail?.Company?.slug) ? true : false;
};

export const getCompanyName = (
  userDetail: any,
  islabel: boolean = false
): string => {
  if (userDetail?.Company?.slug === "LW") {
    return "Lowe's";
  } else {
    return userDetail?.Company?.name || "";
  }
};

export const checkRolePermission = (userDataRole: any, role: any) => {
  if (role === "regionalManager") {
    return userDataRole?.region_id ? true : false;
  }
};

// Function to prevent spaces in input for certain fields.
export const removeSpaceOnly = (e: any) => {
  const inputValue = e.target.value;
  const key = e.keyCode ? e.keyCode : e.which;

  // Allowing spaces only if the input value is not empty and not starting with a space
  if (inputValue.length === 0 && key === 32) {
    e.preventDefault();
  }
};

export const numberOnly = (e: any) => {
  const key = e.keyCode ? e.keyCode : e.which;
  if (
    !(
      [8, 9, 13, 27, 46].indexOf(key) !== -1 ||
      (key === 65 && (e.ctrlKey || e.metaKey)) ||
      (key >= 35 && key <= 40) ||
      (key >= 48 && key <= 57 && !(e.shiftKey || e.altKey)) ||
      (key >= 96 && key <= 105)
    )
  )
    e.preventDefault();
};

// Function to determine which sort icon to display
export const sortIcon = (
  key: string,
  col_name: string,
  order: string
): string => {
  if (col_name === key) {
    return order === "asc" ? "topactiveArrow.svg" : "downactiveArrow.svg";
  } else {
    return "disabledArrow.svg";
  }
};

// Function to get the quarter and year from a given date
export const getQuarterYear = (date: string | number): string => {
  // Calculate the quarter based on the month of the given date
  if (date) {
    const quarter = Math.ceil(Number(moment.utc(date).format("MM")) / 3);

    // Get the year from the given date
    const year = Number(moment.utc(date).format("YYYY"));

    // Return the formatted quarter and year
    return `Q${quarter} ${year}`;
  } else {
    return "";
  }
};

// Function to process graph data and return an array of GraphDataItems
export const getGraphDataHorizontal = (
  res: {
    data: { contributor?: ContributorItem[]; detractor?: DetractorItem[]; };
  },
  key: any = null
): GraphDataItem[] => {
  let regionPageArr: GraphDataItem[] = [];
  let regionPageArrMerge: GraphDataItem[] = [];

  // Process contributor data
  if (res?.data?.contributor) {
    regionPageArr = res.data.contributor
      .filter((i) => i.name !== key)
      .map((i) => ({
        ...i,
        name: i.name,
        y: i.value,
        color: i.color,
        yAxis: 1,
        dataLabels: [
          {
            inside: false,
            enabled: true,
            rotation: 0,
            x: 32,
            overflow: "none",
            allowOverlap: false,
            color: (styles.darkGray),
            align: "center",
            crop: false,
            formatter() {
              return "";
            },
          },
        ],
        type: "column",
      }));
  }

  // Process detractor data
  if (res?.data?.detractor) {
    const detractorData = res.data.detractor;
    regionPageArrMerge = res?.data?.detractor
      .filter((i) => i.name !== key)
      .map((i) => {
        const yValue =
          -Number(i.value) -
          ((detractorData[detractorData?.length - 1]?.value || 0) - (detractorData[0]?.value || 0));
        return {
          ...i,
          name: i.name,
          y: yValue,
          yValue: -Number(i.value),
          color: i.color,
          yAxis: 0,
          type: "column",
          dataLabels: [
            {
              inside: false,
              enabled: true,
              rotation: 0,
              x: -30,
              overflow: "none",
              allowOverlap: false,
              color: (styles.darkGray),
              align: "center",
              crop: false,
              formatter() {
                return "";
              },
            },
          ],
        };
      });
  }

  // Return merged array of contributor and detractor data
  return [...regionPageArr, ...regionPageArrMerge];
};


export const capitalizeText = (str: string) => {
  return str
    ?.toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

//   region select Dropdown
export const getRegionOptions = (data: any) => {
  let regionOption =
    data?.length !== 0 &&
    data?.map((elem: any) => {
      return { label: elem?.name, value: elem?.id?.toString() };
    });

  if (regionOption?.length) {
    regionOption = [{ label: "All Regions", value: "" }, ...regionOption];
  } else {
    regionOption = [{ label: "All Regions", value: "" }];
  }
  return regionOption;
};

export const getDivisionOptions = (data: any) => {
  let divisionOption =
    data?.length !== 0 &&
    data?.map((elem: any) => {
      return { label: elem?.name, value: elem?.id?.toString() };
    });

  if (divisionOption?.length) {
    divisionOption = [{ label: "All Divisions", value: "" }, ...divisionOption];
  } else {
    divisionOption = [{ label: "All Divisions", value: "" }];
  }
  return divisionOption;
};

export const getRegionOptionsBenchmark = (data: any) => {
  let regionOption =
    data?.length !== 0 &&
    data?.map((elem: any) => {
      return { label: elem?.region_name, value: elem?.id.toString() };
    });

  if (regionOption?.length) {
    regionOption = [...regionOption];
  } else {
    regionOption = [];
  }
  return regionOption;
};
//   Quarter select Dropdown
export const getQuarterOptions = (data: any) => {
  return getQuarters(data).map((i: any) => ({
    value: i?.value,
    label: i?.name,
  }));
};
//   Yearly select Dropdown
export const getYearOptions = (data: any) => {
  return yearList(data).map((x: any) => ({ label: x, value: x }));
};

// pagination dropdown option
export const getPaginationOptions = (data: any) => {
  return data.map((x: any) => ({ label: x, value: x }));
};
//   lane select Dropdown
export const getLaneOptions = () => {
  return pageSizeList.map((x: any) => ({ label: `${x} Lanes`, value: x }));
};

export const getDropDownOptions = (list: any, key: any, value: any) => {
  let options = (list?.length > 0 && list?.map((x: any) => ({ label: capitalizeText(x[key]), value: x[value] }))) || [];
  return [{ label: "All", value: "" }, ...options];
};

export const getGraphData = (
  res: { data: { contributor?: Contributor[]; detractor?: Detractor[]; }; },
  key: any = null
) => {
  let regionPageArr: any = [];
  let regionPageArrMerge: any = [];

  if (res?.data?.contributor) {
    regionPageArr = res?.data?.contributor
      ?.filter((i) => i?.name !== key)
      ?.map((i) => ({
        ...i,
        name: i.name?.split("_").join(" to "),
        y: i.value,
        color: i.color,
        yAxis: 1,
        dataLabels: [
          {
            inside: false,
            enabled: true,
            rotation: 0,
            x: 32,
            overflow: "none",
            allowOverlap: false,
            color: (styles.darkGray),
            align: "center",
            crop: false,
            formatter(_: any) {
              const point: any = this;
              let value: any = Math.abs(point.y).toFixed(1);
              let returnValue = formatNumber(true, value, 1);
              return `+ ${returnValue}`;
            },
          },
        ],
        type: "column",
      }));
  }

  if (res?.data?.detractor) {
    regionPageArrMerge = res?.data?.detractor
      ?.filter((i) => i?.name !== key)
      ?.map((i) => ({
        ...i,
        name: i.name,
        y: -Number(i.value),
        color: i.color,
        yAxis: 0,
        type: "column",
        dataLabels: [
          {
            inside: false,
            enabled: true,
            rotation: 0,
            x: -30,
            overflow: "none",
            allowOverlap: false,
            color: (styles.darkGray),
            align: "center",
            crop: false,
            formatter: function (_: any) {
              const point: any = this;
              let value: any = Math.abs(point.y).toFixed(1);
              let returnValue = formatNumber(true, value, 1);
              return `- ${returnValue}`;
            },
          },
        ],
      }));
  }

  return [...regionPageArr, ...regionPageArrMerge];
};

export const formatNumber = (
  isDecimalNumber: boolean,
  number: any,
  place?: number,
  maxPlace?: number
) => {
  let numberData: any = "0";
  let decimalPoint = Math.pow(10, place ?? 1);
  if (number !== "N/A") {
    if (!isDecimalNumber) {
      numberData = Number.parseInt(number || 0)?.toLocaleString("en-US");
    } else {
      numberData = (
        Math.floor(Math.round(Number.parseFloat(number || 0) * decimalPoint)) / decimalPoint
      )?.toLocaleString("en-US", {
        minimumFractionDigits: place,
        maximumFractionDigits: maxPlace ?? place,
      });
    }
  }
  return numberData;
};

export const getLaneSubTitle = (isChecked: boolean) =>
  isChecked ? "Total Lane Emissions" : "Lane Emissions Intensity";
export const getFacilitySubTitle = (isChecked: boolean) =>
  isChecked ? "Total Facility Emissions" : "Facility Emissions Intensity";

export const getRevenueType = (revenueType: number) =>
  revenueType === 0 ? "Ton-Mile" : "revenue dollar";


export const getBackgroundColor = (isSelected: boolean) =>
  isSelected ? (styles.primary) : "white";
export const getSelectColor = (isSelected: boolean) =>
  isSelected ? "white" : "black";

export const sortList = (list: any, colName: string, order: string) => {
  let name = colName;
  const newList = [...list]?.sort((a: any, b: any) => {
    if (order === "asc") {
      if (a[name] < b[name]) return -1;
      if (a[name] > b[name]) return 1;
      return 0;
    } else {
      if (a[name] > b[name]) return -1;
      if (a[name] < b[name]) return 1;
      return 0;
    }
  }
  );
  return newList;
};

export const avgList = (list: any, colName: string) => {
  return list?.reduce(function (acc: any, obj: any) { return acc + obj[colName]; }, 0) / list.length;
};

export const getSubTitleFacility = (
  isChecked: boolean,
  regionalLevel: string,
  list: any
) => {
  let regionalName = "All Regions";
  if (regionalLevel !== "" && list) {
    const region = list.filter((e: any) => {
      return e.id === Number.parseInt(regionalLevel);
    })[0]?.name;
    regionalName = region + " Region";
  }

  return `${isChecked ? "Total Emissions" : "Emissions Intensity"
    } of ${regionalName}`;
};

export const showSign = (isChecked: boolean) => (isChecked ? "-" : "+");

export const getUnitSign = (isUnit: boolean) => (isUnit ? "gCO2e/Ton-Mile of freight" : "tCO2e");

export const getGramUnitSign = (isUnit: boolean) => (isUnit ? "tCO2e" : "g");

export const getMaxValue = (list: any) => Math.max(...(list || [1])) * 1.1;

export const getFacilityName = (name: string) => `${name || ""}`;

export const getInBoundName = (isChecked: boolean, type: string) =>
  isChecked
    ? `${type} Freight Total Emissions`
    : `${type} Freight Emissions Intensity`;

export const getEmmisonName = (checkedRegion: boolean) =>
  checkedRegion ? "Total Emissions" : "Emissions Intensity";

export const getColumnChartHeading = (
  data: any,
  type: string
) => `Average of all ${type} (
    ${formatNumber(true, data?.average, 1)}
    ${data?.unit} )`;

export const getEmissionDetailId = (list: any, code: number, type: any) => {
  let slugcode = listCode?.filter((i: any) => i?.post_code === code)[0]?.slug;
  return list?.data?.filter((i: any) => i?.slug === slugcode)[0]?.[type] || 0;
};
export const listCodeFuc = (code: any) => {
  let regionCode = listCode?.filter((i) => i?.post_code === code)[0]
    ?.region_code;
  return listCode
    ?.filter((i) => i?.region_code === regionCode)
    ?.map((i) => i?.post_code);
};
export const getRegionNameDetail = (list: any, code: any, type: any) => {
  let slugcode = listCode?.filter((i: any) => i?.post_code === code)[0]?.slug;
  return list?.data?.filter((i: any) => i?.slug === slugcode)[0]?.[type] || "";
};

export const getEmissionDetail = (list: any, code: any, type: any) => {
  let slugcode = listCode?.filter((i: any) => i?.post_code === code)[0]?.slug;
  return (
    Math.round(
      (list?.data?.filter((i: any) => i?.slug === slugcode)[0]?.[type] || 0) *
      10
    ) / 10
  )?.toLocaleString("en-US", { minimumFractionDigits: 1 });
};


export const getPriority = (type: number) => {
  let priority = "";

  switch (type) {
    case 1:
      priority = "HIGHEST PRIORITY";
      break;
    case 2:
      priority = "MEDIUM PRIORITY";
      break;
    default:
      priority = "LOW PRIORITY";
  }
  return priority;
};

export const getPriorityColor = (type: string) => {
  let colorCode = "";

  switch (type) {
    case "HIGHEST PRIORITY":
      colorCode = "high_priority";
      break;
    case "MEDIUM PRIORITY":
      colorCode = "medium_priority";
      break;
    default:
      colorCode = "low_priority";
  }
  return colorCode;
};

export const setRegionID = (id: string) => {
  if (id === "") {
    localStorage.removeItem("regionalLevel");
  } else {
    localStorage.setItem("regionalLevel", id);
  }
};

export const setDivisionID = (id: string) => {
  if (id === "") {
    localStorage.removeItem("divisionLevel");
  } else {
    localStorage.setItem("divisionLevel", id);
  }
};

export const getQuarterData = (id: any) => {
  return id !== "" ? Number.parseInt(id) : ""
};

export const getLaneTitle = (isChecked: boolean) =>
  isChecked ? `Intensity` : ``;

//  Get percentage
export const getPercentage = (
  regionId: any,
  type: string,
  emissionPercentage?: number
) => {
  let percentageDto = regionList?.filter(
    (i: any) => i?.id === Number.parseInt(regionId)
  )[0];
  let percentage = 0;
  let emissionAlternativeFuelPercentage = 1;
  if (type === "alternative_fuel") {
    emissionAlternativeFuelPercentage = emissionPercentage || 1;
  }
  switch (type) {
    case "carrier_shift":
      percentage = percentageDto?.carrierShiftpercentage || 0;
      break;
    case "alternative_fuel":
      percentage = percentageDto?.alternativeShiftpercentage || 0;

      break;
    case "modal_shift":
      percentage = percentageDto?.modalShiftpercentage || 0;

      break;
  }
  return percentage * emissionAlternativeFuelPercentage;
};


//  Get Modal shift emission
export const getModalShiftEmissions = (
  value: any,
  regionsLevel: any,
  key: any,
  index: any,
  emissionAlternativeFuelPercentage?: number,
  emissionDto?: any
) => {
  let percentage = getPercentage(regionsLevel, key);
  let emmision: any = 0;

  if (key === "modal_shift") {
    switch (index) {
      case 0:
        emmision = (
          Math.round(value * ((100 - percentage) / 100) * 0.4 * 10) / 10
        ).toLocaleString("en-US", {
          minimumFractionDigits: 1,
        });
        break;
      case 1:
        emmision = "N/A";

        break;
      case 2:
        emmision = (
          Math.round(value * ((100 - percentage) / 100) * 0.6 * 10) / 10
        ).toLocaleString("en-US", {
          minimumFractionDigits: 1,
        });

        break;
    }
  } else if (key === "alternative_fuel") {
    emmision = formatNumber(
      true,
      (value *
        (100 -
          getPercentage(
            regionsLevel,
            key,
            emissionAlternativeFuelPercentage
          ))) /
      100,
      1
    );
  } else {
    emmision = emissionDto?.emissions
      ? formatNumber(true, emissionDto?.emissions || 0, 1)
      : "N/A";
  }
  return emmision;
};

export const getCarrierEmissions = (emissionValue: any, shipment: any, carrierEmissionValue: any, carrierShipment: any) => {
  const emmisionPerShipment = emissionValue / shipment;
  const carrierEmmisionPerShipment = carrierEmissionValue / carrierShipment;
  return (100 - ((emmisionPerShipment - carrierEmmisionPerShipment) / emmisionPerShipment) * 100)?.toFixed(1);
};

export const getEmissionsFormate = (value: any) => {

  return value ? formatNumber(true, value || 0, 1) : "N/A";
};

export const getEmmisonPercentage = (projectDetails: any, index: number) => {
  if (projectDetails?.data?.projectDetail?.type === "modal_shift") {
    return getModalShiftEmissions(
      projectDetails?.data?.projectDetail?.actual_emission,
      projectDetails?.data?.projectDetail?.region_id,
      projectDetails?.data?.projectDetail?.type,
      index
    );
  } else if (projectDetails?.data?.projectDetail?.type === "alternative_fuel") {
    return formatNumber(
      true,
      projectDetails?.data?.projectDetail?.actual_emission *
      ((100 -
        Number.parseInt(
          projectDetails?.data?.projectDetail?.emission_percent
        )) /
        100),
      1
    );
  } else {
    return "N/A";
  }
};

export const getMarkerR = (regionId: any) => {
  return (
    regionList?.filter((i) => i?.id === Number.parseInt(regionId))[0]?.marker || null
  );
};

export const getTitleDecarb = (key: any) => {
  if (key === "modal_shift") {
    return "Modal Shift";
  }
  if (key === "alternative_fuel") {
    return "Alternative Fuel";
  }
  if (key === "carrier_shift") {
    return "Carrier Shift";
  }
  if (key === "fuel_stop") {
    return "Fuel Stop Recommendations";
  }
};

export const getStrokeOpacity = (type: string) => (type === "TRANSIT" ? 0 : 1);
export const getMapPath = (type: string) =>
  type === "TRANSIT"
    ? google.maps.SymbolPath.CIRCLE
    : google.maps.SymbolPath.FORWARD_OPEN_ARROW;
export const getMapTravelMode = (type: string) =>
  type === "Rail"
    ? google.maps.TravelMode.TRANSIT
    : google.maps.TravelMode.WALKING;

export const getMapIcon = (type: string) =>
  type === "TRANSIT"
    ? [
      {
        icon: {
          path: getMapPath(type),
          fillColor: (styles.limeGreen),
          fillOpacity: 1,
          scale: 2,
          strokeColor: (styles.limeGreen),
          strokeOpacity: 1,
        },
        offset: "0",
        repeat: "10px",
      },
    ]
    : null;



export const getDecarbFormate = (
  type: string,
  dto: any,
  key: string,
  round: number
) => {
  if (type === "carrier_shift") {
    return formatNumber(true, dto[key], round);
  } else {
    return formatNumber(true, dto?.lanedto[key], round);
  }
};

export const isActiveClassDecarb = (code: string, selectedCode: string) => {
  return code === selectedCode ? "table-active" : "";
};

export const typeCheck = (
  cond: boolean,
  firstStatment: any,
  secondStatment: any
) => {
  return cond ? firstStatment : secondStatment;
};

export const checkNumberUndefined = (data: any) => {
  return data || 0;
};

export const returnBandNumber = (benchmarkDto: any) => {
  return benchmarkDto?.data?.map((x: any) => ({
    label: x.band_name,
    value: x.band_no,
  }));
};
export const returnBinary = (data: boolean) => {
  return data ? 1 : 0;
};
export const benchmarkType = (params: any) =>
  params?.type === "region" ? "US Region" : "Freight Lanes";

export const benchmarkTypekey = (params: any) =>
  params?.type === "region" ? "regionBenchmarks" : "laneBenchmarks";

export const getOrder = (order: string) => (order === "desc" ? "asc" : "desc");

export const getFirstLastElement = (list: any) => {
  const listCopy: any = [...list];
  return [listCopy[0], listCopy?.pop()];
};


export const timeConverter = (time: number) => {
  if (time) {
    return `${Math.trunc((time / 3600))}Hr ${Math.trunc((time / 60)) % 60}Mins`;
  } else {
    return 0;
  }

};

export const distanceConverter = (distance: number, isMile: boolean = false) => {
  if (distance) {
    return isMile ? distance : distance * kmToMilesConst;
  } else {
    return 0;
  }
};


export const getLowHighImage = (value1: any, value2: any, isClassName?: boolean, place: number = 1) => {
  if (isClassName) {
    return Number(value1?.toFixed(place)) > Number(value2?.toFixed(place)) ? 'highData' : '';
  }
  return Number(value1?.toFixed(place)) > Number(value2?.toFixed(place)) ? "redDownArrow.svg" : "greenArrowDowm.svg";
};


export const isCancelRequest = (res: any) => {
  return res?.code === "ERR_CANCELED" || res === "canceled";
};

// Function to generate and download CSV
export const downloadCSV = (csvData: any, fileName: string, type?: string, fileFormate?: string) => {
  // Create a Blob and create a link to trigger the download
  const blob = new Blob([csvData], { type: type || 'text/csv' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const formatDate = (date: any) => {
  return moment(date).format("DD MMM YYYY");
};

// Navigate to carrier from lane intermodal map
export const navigateToCarrier = (code: string, laneName: string, projectId?: any, year?: any) => {
  if (projectId) {
    return `/carrier-overview/${code}/detail/${laneName}/${year}/0/${projectId}`;
  } else {
    return `/carrier-overview/${code}/detail/${laneName}/${year}/0`;
  }
};

export const executeScroll = (ref: any) => {
  if (ref?.current) {
    return ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};


export const getProjectedCost = (
  total: any,
  avg: any,
  dollarPerMile: number,
  lane: any,
  routeType: string,
  fuelStops?: any,
  laneDto?: any
) => {
  if (routeType === "alternative_fuel") {
    const selectedFuelStopList = fuelStops?.selectedFuelStop

    const sumSelectedFuelStop = selectedFuelStopList?.reduce((acc: any, obj: any) => { return acc + obj.cost_premium_const }, 0) || 0;
    const countSelectedFuelStop = selectedFuelStopList?.length || 0;
    let costPremiumConstFraction = 0
    let avgCoutImpactFraction = 0
    if (countSelectedFuelStop > 0) {
      costPremiumConstFraction = costPremiumConstFraction + sumSelectedFuelStop
      avgCoutImpactFraction = avgCoutImpactFraction + countSelectedFuelStop
    }

    if (fuelStops?.showFuelStops && countSelectedFuelStop === 0) {
      costPremiumConstFraction = costPremiumConstFraction + lane?.fuel_stop?.cost_premium_const || 0
      avgCoutImpactFraction = avgCoutImpactFraction + 1
    }

    if (fuelStops?.showFuelStopsEV) {
      costPremiumConstFraction = costPremiumConstFraction + laneDto?.ev_fuel_stop?.cost_premium_const
      avgCoutImpactFraction = avgCoutImpactFraction + 1
    }
    return {
      cost: formatNumber(true, (Math.abs(((Math.round(total * 10) / 10) * (costPremiumConstFraction / avgCoutImpactFraction)) - ((Math.round(avg * 10) / 10)))) / ((Math.round(avg * 10) / 10)) * 100, 0),
      costPremiumConstFraction: costPremiumConstFraction / avgCoutImpactFraction
    }

  } else {
    return {
      cost: intermodalCost(avg, lane?.laneIntermodalDto?.laneIntermodalCordinateData?.road_distance, dollarPerMile, lane?.laneIntermodalDto?.baseLine?.fuel_stop?.cost_premium_const, lane?.laneIntermodalDto?.laneIntermodalCordinateData?.rail_distance, lane?.laneIntermodalDto?.laneIntermodalCordinateData?.cost_per_mile),
      costPremiumConstFraction: lane?.laneIntermodalDto?.baseLine?.fuel_stop?.cost_premium_const
    }
  }
};

export const intermodalCost = (avg: number, road_distance: number, dollarPerMile: number, cost_premium_const: number, rail_distance: number, cost_per_mile: number) => {

  let cost = (road_distance * kmToMilesConst * dollarPerMile * cost_premium_const) + (rail_distance * kmToMilesConst * cost_per_mile);

  return formatNumber(true, (Math.abs(((Math.round(cost * 10) / 10)) - ((Math.round(avg * 10) / 10) * dollarPerMile))) / ((Math.round(avg * 10) / 10) * dollarPerMile) * 100, 0);
};

export const getCheckedValue = (isCheckedObj: any, isChecked: boolean, laneFuelStop: any) => {
  const alternativeFuel = laneFuelStop?.filter((res: any) => (res.product_code !== evProductCode)).length
  const evFuel = laneFuelStop?.filter((res: any) => (res.product_code === evProductCode)).length
  // const rdFuel = laneFuelStop?.filter((res: any) => (res.product_code === rdProductCode)).length
  const rdFuel = 0
  if (Object.values(isCheckedObj)?.filter((res: any) => res).length === 0) {
    if (alternativeFuel > 0) {
      return { ...isCheckedObj, showFuelStops: true }
    }
    if (evFuel > 0) {
      return { ...isCheckedObj, showFuelStopsEV: true }
    }

    if (rdFuel > 0) {
      return { ...isCheckedObj, showFuelStopsRD: true }
    }

  }
  return isCheckedObj
}

export const getLaneEmission = (
  lane: any,
  scenarioDetails: any,
  totalMiles: number,
  routeType: string,
  fuelStops?: any,
  laneDto?: any
) => {
  let oldEmission = 0;
  let newEmission = 0;
  let percentEmissions = 0;
  let sumSelectedFuelStop = 0;
  let impactFraction = 0
  if (routeType === "alternative_fuel") {

    oldEmission = scenarioDetails?.data?.emission_intensity * totalMiles * 22.5;
    const selectedFuelStopList = fuelStops?.selectedFuelStop
    sumSelectedFuelStop = selectedFuelStopList?.reduce((acc: any, obj: any) => { return acc + obj.impact_fraction; }, 0) || 0;
    let avgCoutImpactFraction = 0
    const countSelectedFuelStop = selectedFuelStopList?.length;

    if (countSelectedFuelStop > 0) {
      impactFraction = impactFraction + sumSelectedFuelStop
      avgCoutImpactFraction = avgCoutImpactFraction + countSelectedFuelStop
    }

    if (fuelStops?.showFuelStops && countSelectedFuelStop === 0) {
      impactFraction = impactFraction + lane?.fuel_stop?.impact_fraction
      avgCoutImpactFraction = avgCoutImpactFraction + 1
    }

    if (fuelStops?.showFuelStopsEV) {
      impactFraction = impactFraction + laneDto?.ev_fuel_stop?.impact_fraction
      avgCoutImpactFraction = avgCoutImpactFraction + 1
    }


    newEmission = scenarioDetails?.data?.emission_intensity * totalMiles * 22.5 * (impactFraction / avgCoutImpactFraction);
    percentEmissions = (Math.abs((impactFraction / avgCoutImpactFraction) - 1) / 1 * 100);
    return { oldEmission, newEmission, percentEmissions };
  }
  const totalRoadDistance = lane?.laneIntermodalDto?.laneIntermodalCordinateData?.road_distance;
  const totalRailDistance = lane?.laneIntermodalDto?.laneIntermodalCordinateData?.rail_distance;

  let emissionIntermodal = intermodalReduction(totalRoadDistance, totalRailDistance, scenarioDetails?.data?.emission_intensity, lane?.laneIntermodalDto?.baseLine?.distance, lane?.laneIntermodalDto?.baseLine?.fuel_stop?.impact_fraction, lane?.laneIntermodalDto?.laneIntermodalCordinateData?.emission_const);

  oldEmission = emissionIntermodal?.oldEmission || 0;
  newEmission = emissionIntermodal?.newEmission || 0;
  percentEmissions = emissionIntermodal?.percentEmissions || 0;

  return { oldEmission, newEmission, percentEmissions };


};

export const intermodalReduction = (road_distance: any, rail_distance: any, emission_intensity: any, distance: any, impact_fraction: any, emission_const: any) => {
  let oldEmission: number = emission_intensity * distance * 22.5;
  let newEmission: number = emission_intensity * 22.5 * ((impact_fraction * road_distance) + ((emission_const * rail_distance)));
  let percentEmissions: number = (Math.abs(newEmission - oldEmission) / oldEmission * 100);

  return { oldEmission, newEmission, percentEmissions };
};

export const filterLane = (list: any, distance: number, numOfFuelStops: any) => {
  return list?.filter((item: any) => {
    return (item.distance !== distance && item.recommendedKLaneFuelStop.length !== numOfFuelStops.length) && ((item.distance <= distance * 1.25 && item.distance >= distance && item.recommendedKLaneFuelStop.length > numOfFuelStops.length) || (item.distance <= distance && item.recommendedKLaneFuelStop.length <= numOfFuelStops.length));
  }) || [];

};

export const getPercentageDistanceTime = (actualValue: number, total: number) => {
  if (total > 0) {
    return ((actualValue - total) / total) * 100;
  } else {
    return 0;
  }
};

export const getFileStatusIcon = (status: number | string) => {
  switch (status) {
    case 1:
      return "cancel.svg";
    case 2:
      return "uploaded.svg";
    case 4:
      return "failedIcon.svg";
    case 3:
      return "ingested.svg";
    case 7:
      return "partiallyIngested.svg";
    case 5:
      return "analyzed.svg";
    case 6:
      return "uploadingIcon.svg";
    case "folder":
      return "folder.svg";
    case "file":
      return "file.svg";
    default:
      return "uploadingIcon.svg";
  }
};

export const getBidFileStatusIcon = (status: number | string) => {
  switch (status) {
    case 1:
      return "cancel.svg";
    case 2:
      return "uploaded.svg";
    case 3:
      return "failedIcon.svg";
    case 5:
      return "processed.svg";
    case "file":
      return "file.svg";
    default:
      return "uploaded.svg";
  }
};

export const getUserStatus = (status: any) => {
  switch (status) {
    case 0:
      return { name: "Inactive", color: "inactive" };
    case 1:
      return { name: "Active", color: "active" };
    default:
      return { name: "Deactivated", color: "deactive" };
  }
};

export const getFileStatusCode = (status: string) => {
  switch (status) {
    case "Cancelled":
      return 1;
    case "Uploaded":
      return 2;
    case "Failed":
      return 4;
    case "Ingested":
      return 3;
    case "Ingestion in progress":
      return 7;
    case "Analyzed":
      return 5;
    case "Uploading":
      return 6;
    default:
      return 6;
  }
};

export const getBidFileStatusCode = (status: string) => {
  switch (status) {
    case "Cancelled":
      return 1;
    case "Uploaded":
      return 2;
    case "Failed":
      return 3;
    case "Uploading":
      return 4;
    case "Processed":
      return 5;
    case "Processing":
      return 5;
    default:
      return 2;
  }
};

export const getfolderPath = (folderPath: any, isfilePath: boolean, name?: string) => {
  if (isfilePath) {
    return folderPath ? `${folderPath}/${name}` : name;
  } else {

    return folderPath ? folderPath : "/";
  }

};

export const getFuelStopCheck = (fuleStop: any, key: string) => {
  if (key === "alternative_fuel") {
    if (fuleStop?.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  return true;
};
export const getCurrentQuarter = (showQuarter?: boolean) => {
  const date = new Date();
  return showQuarter ? `Q${Math.floor(date.getMonth() / 3 + 1)}` : '';
};


export const getProjectedDecarbCost = (distance: number, dollarPerMile: number, costPremiumConst: number) => {
  return (((distance * dollarPerMile * costPremiumConst) - (distance * dollarPerMile)) / (distance * dollarPerMile)) * 100;
};

export const getProjectedDecarbModalShiftCost = (railDistance: number, roadDistance: number, baselineDistance: number, dollarPerMile: number, costPremiumConst: number, costPerMile: number) => {
  let avg = baselineDistance * kmToMilesConst * dollarPerMile;
  let cost = (roadDistance * kmToMilesConst * dollarPerMile * costPremiumConst) + (railDistance * kmToMilesConst * costPerMile);
  return formatNumber(true, (Math.abs(((Math.round(cost * 10) / 10)) - ((Math.round(avg * 10) / 10)))) / ((Math.round(avg * 10) / 10)) * 100, 0);
};

export const getEmissionsReductionDecarbCost = (distance: number, intensity: number, impactFraction: number) => {
  const newEmission = distance * intensity * impactFraction * 22.5;
  const oldEmission = distance * intensity * 22.5;
  const emissionPercentage: number = (Math.abs((newEmission - oldEmission) / oldEmission * 100));
  return emissionPercentage;

};

export const getEmissionsReductionDecarbModalShift = (railDistance: number, roadDistance: number, baselineDistance: number, emissionConst: number, intensity: number, impactFraction: number) => {
  const newEmission = intensity * ((roadDistance * kmToMilesConst * impactFraction) + (railDistance * kmToMilesConst * emissionConst)) * 22.5;
  const oldEmission = baselineDistance * kmToMilesConst * intensity * 22.5;
  const emissionPercentage: number = (Math.abs(((newEmission - oldEmission) / oldEmission) * 100));
  return Math.abs(emissionPercentage);

};

// Function to download a file with fileUrl
export const downloadFile = async (fileUrl: string, fileName: string) => {
  try {
    // Create a download link with appropriate filename
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', fileName);
    // Trigger the download in the browser
    link.click();
    link.remove();
  } catch (error) {
    toast.error('Error downloading file');
  }
};


export const ecryptDataFunction = (password: string) => {
  return CryptoJS.AES.encrypt(JSON.stringify(password), process.env.REACT_APP_EN_KEY).toString();
};

export const decryptDataFunction = (password: string) => {
  const bytes = CryptoJS.AES.decrypt(password, process.env.REACT_APP_EN_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};


export const addIsChecked = (list: any) => {

  return list.map((item: any) => {
    // iteam?.child[0]?.parent_id
    const newItem = { ...item, id: item?.parent_id === 0 ? item?.child[0]?.parent_id : item?.id, isChecked: false }; // Create a new object with isChecked property
    if (newItem.child && newItem.child.length > 0) {
      newItem.child = addIsChecked(newItem.child); // Recursively add isChecked property to child items
    }
    return newItem;
  });

};

export const updateIsCheckedBySlug = (data: any, slug: string) => {
  return data.map((item: any) => {
    if (item.slug === slug) {
      item.isChecked = true;
      if (item.child && item.child.length > 0) {
        item.child = updateIsCheckedById(item.child, item?.id, item?.id, true);
      }
    }
    return item;
  });
};


export const updateIsCheckedById = (list: any, id: number, parentId: number, status: boolean) => {
  return list.map((item: any) => {
    if (item.id === id || item.parent_id === parentId) {
      return { ...item, isChecked: status, child: item.child ? updateIsCheckedById(item.child, id, item?.id, status) : [] };
    } else if (item.child && item.child.length > 0) {
      return { ...item, child: updateIsCheckedById(item.child, id, parentId, status) };
    }
    return item;
  });
};

export const updateIsCheckedParentById = (list: any, id: number, status: boolean) => {
  const updateParentChecked = (item: any) => {
    if (!item?.parent_id) return;
    const parent = list?.find((parentItem: any) => parentItem?.id === item?.parent_id);
    if (!parent) return;
    parent.isChecked = status;
    updateParentChecked(parent);
  };

  const updateChecked = (item: any) => {
    if (item.id === id) {
      item.isChecked = status;
      updateParentChecked(item);
    }
    if (item?.child) {
      item?.child?.forEach((child: any) => updateChecked(child));
    }
  };

  list?.forEach((item: any) => updateChecked(item));
  return list;
};


export const updateIsCheckedParentByIdT = (list: any) => {

  const updateChecked = (item: any) => {
    if (item?.child && item?.child?.length > 0) {
      item.isChecked = item?.child?.every((child: any) => child?.isChecked);
      item?.child?.forEach(updateChecked);
    }
  };

  list?.forEach((item: any) => updateChecked(item));

  const parentIds = new Set(list.filter((item: any) => item?.parent_id !== 0).map((item: any) => item?.parent_id));
  parentIds?.forEach(parentId => {
    const parent = list?.find((item: any) => item?.id === parentId);
    if (parent) {
      parent.isChecked = list?.filter((item: any) => item?.parent_id === parentId)?.every((item: any) => item?.isChecked);
    }
  });

  // Update top-level parent elements
  const topLevelParents = list?.filter((item: any) => item?.parent_id === 0);
  topLevelParents?.forEach((parent: any) => {
    parent.isChecked = parent?.child?.every((child: any) => child?.isChecked);
  });

  return list;

};

export const getCheckedIds = (list: any) => {
  let checkedIds: any = [];
  list.forEach((item: any) => {
    if (item.isChecked) {
      checkedIds.push(item.id);
    }
    if (item.child && item.child.length > 0) {
      checkedIds = checkedIds.concat(getCheckedIds(item.child));
    }
  });
  return checkedIds;
};
export const handleProfileImage = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
  const target = e.target as HTMLImageElement;
  target.src = require("assets/images/defaultError.png");
};

export const getImageUrl = (imageName: string) => {
  return imageURL + imageName + "?" + imageToken;
};
export const isPermissionChecked = (data: any, identifier: any) => {
  const userdata: any = getLocalStorage("loginDetails");

  if (isCompanyEnable(userdata, [companySlug.adm]) && identifier === routeKey?.CarrierShift) {
    return false
  }

  for (let item of data) {
    if (item?.slug === identifier) {
      return item;
    }
    if (item?.child?.length > 0) {
      let childChecked: any = isPermissionChecked(item?.child, identifier);
      if (childChecked) {
        return childChecked;
      }
    }
  }
  return false;
};

export const isApplicationTypeChecked = (data: any, identifier: any) => {
  for (let item of data || []) {
    if (item?.slug === identifier) {
      return isAnyChildChecked(item.child);
    }
    if (item?.child?.length > 0) {
      let childChecked: any = isApplicationTypeChecked(item?.child, identifier);
      if (childChecked) {
        return true;
      }
    }
  }
  return false;
};

const isAnyChildChecked = (data: any) => {
  for (let item of data) {
    if (item?.isChecked) {
      return true;
    }
    if (item?.child?.length > 0) {
      if (isAnyChildChecked(item?.child)) {
        return true;
      }
    }
  }
  return false;
};


export const filterLeverOption = (data: any) => {
  const byLeverOption = [
    { value: "", label: "By Lever" },

  ];
  if (isPermissionChecked(data, routeKey?.ModalShift)?.isChecked) {
    byLeverOption.push({ value: "modal_shift", label: " Modal Shift" });
  }
  if (isPermissionChecked(data, routeKey?.AlternativeFuel)?.isChecked) {
    byLeverOption.push({ value: "alternative_fuel", label: "Alternative Fuel" });
  }
  if (isPermissionChecked(data, routeKey?.CarrierShift)?.isChecked) {
    byLeverOption.push({ value: "carrier_shift", label: "Carrier Shift" });
  }
  return byLeverOption;
};

export const getAdminUrl = (data: any) => {

  if (isPermissionChecked(data, routeKey?.UserManagement)?.isChecked) {
    return "/user-management";
  }
  else if (isPermissionChecked(data, routeKey?.DataManagement)?.isChecked) {
    return "/data-management";
  } else {
    return "/user-management";
  }
};

export const getProductTypeImpactFraction = (data: any, fuelStopList: any, fuelStopDto: any) => {
  let sum = 0;
  let count = 0;
  
  if (data?.is_ev) {
    sum = sum + fuelStopDto?.ev_fuel_stop?.impact_fraction;
    count = count + 1;
  }
  if (data?.fuel_type !== "") {
    const selectedEV = fuelStopList?.filter((item: any) => data?.fuel_type?.split(",")?.includes(item.code));
    sum = sum + selectedEV?.reduce((acc: any, obj: any) => { return acc + obj.impact_fraction; }, 0);
    count = count + selectedEV.length;
  }
  return sum / count;
};

export const getFuleType = (fuelName: string, productCode: string, projectDetail: any, alternativeFuelCount: number) => {
  if (projectDetail?.is_ev && !projectDetail?.is_alternative) return fuelName || "N/A";
  if (projectDetail?.is_alternative) return fuelName ? fuelName : productCode;
  if (alternativeFuelCount === 0) return "N/A";
  return fuelName;
};

export const getAddressName = (center: any, callback: (address: string | null) => void) => {
  const geocoder = new window.google.maps.Geocoder();
  const latLng = new window.google.maps.LatLng(center?.latitude, center?.longitude);
  geocoder.geocode({ location: latLng }, (results, status) => {
    if (status === 'OK' && results && results.length > 0) {
      callback(results[0].formatted_address);
    } else {
      callback(null);
    }
  });
};

export const getActiveClass = (routeList: any, location: any, isBar: boolean) => {
  let length = routeList.filter((e: any) => location.pathname.includes(e)).length;
  if (length > 0) {
    return isBar ? "activebar" : "active";
  } else {
    return "";
  }
};

export const getPercentageData = (total: number, fileProcessCount: number) => {
  if (total > 0) {
    return (fileProcessCount / total) * 100;
  } else {
    return 0;
  }
};

export const getSearchodpairUrl = (page: string = "") => {
  if (page === "optimus") {
    return `${actUrl}optimus-origin-destination`;
  } else if (page === "problemLanes") {
    return `search-origin-dest-problem-lanes`;
  } else if (page === "LaneEmissionsComparison") {
    return `${actUrl}get-lane-emission-lanes-origin-dest`;
  } else if (page === "laneReport") {
    return `${actUrl}lane-search-report-management`
  } else {
    return "carrier-search-lane-planing";
  }
};

export const handleDownloadCsvFile = (props: any) => {
  const {
    rowKey = null,
    nameKey = "name",
    regionalLevel,
    year,
    quarterOption,
    quarterDto,
    list,
    fileName,
    tableLabel,
    pId,
    timePeriodList,
    loginDetails,
    regionOption,
    divisionOptions,
    divisionLevel
  } = props
  let data = [];
  // const year = yearlyData
  const quarter = quarterOption?.filter((el: any) => el.value === quarterDto)?.[0]?.label || ""
  const selectedRegion = regionOption?.filter((el: any) => el.value === regionalLevel?.toString())?.[0]?.label || ""

  data.push(["Selected filters"]);

  if (loginDetails?.data?.Company?.slug === companySlug?.pep) {
    const selectedDivision = divisionOptions?.filter((el: any) => el.value === divisionLevel?.toString())?.[0]?.label || "All Divisions"
    data.push(["Division", selectedDivision]);
  }
  if (selectedRegion) {
    data.push(["Region", selectedRegion]);
  }
  if (loginDetails?.data?.Company?.slug === companySlug?.pep) {
    const weeklyName = timePeriodList?.data?.filter((el: any) => el?.id === pId)?.[0]?.name || "All Periods"
    data.push(["Period", weeklyName]);
  }
  data.push(["Year", year]);
  data.push(["Quarter", quarter]);
  data.push([""]);

  data.push([tableLabel, "Emissions Intensity\n(gCO2e/Ton-Mile of freight)", "Total Shipments", "Total Emissions \n (tCO2e)", "Quarter", "Year"]);

  // Add data rows
  list?.data?.forEach((row: any) => {
    const rowData = [
      rowKey ? `${row?.[rowKey]} (${row?.[nameKey]})` : row[nameKey],
      formatNumber(true, row?.intensity?.value, 1),
      formatNumber(true, row?.shipments, 0),
      formatNumber(true, row?.cost?.value, 2),
      quarter,
      year
    ];
    data.push(rowData);
  });
  const csvData = data.map((row: any) => row.map((value: any) => `"${value}"`).join(',')).join('\n');
  downloadCSV(csvData, `${fileName}-${quarter}-${year}.csv`);
}

export const getCurrentMonths = (year: any) => {
  const date = new Date();
  return year?.value === date.getFullYear() ? monthOption.filter((res: any) => res?.value <= date.getMonth()) : monthOption
}

export const checkedNullValue = (value: any, defaultValue: any = 0) => {
  return value === null ? defaultValue : value
}




export const getQuarterName = (
  data: string | number,
  year: string | number
): string => {
  const latestYear = new Date().getFullYear();
  let quarterName: string | number = Number.parseInt(data?.toString(), 10);

  if (!isNaN(quarterName)) {
    // Convert quarter number to its name
    if (quarterName === 0) {
      quarterName = "All Quarters of"
    } else if (quarterName === 1) {
      quarterName = "Q1";
    } else if (quarterName === 2) {
      quarterName = "Q2";
    } else if (quarterName === 3) {
      quarterName = "Q3";
    } else if (quarterName === 4) {
      quarterName = "Q4";
    }
  } else if (latestYear === Number.parseInt(year?.toString(), 10)) {
    // Handle cases where quarter is not provided
    quarterName = "";
  } else {
    quarterName = "All Quarters of";
  }

  return quarterName as string;
};

export const getRegionName = (
  regions: any,
  regionalLevel: string | null,
  isList = false,
  isBenchmark = false
) => {
  if (regionalLevel && regions) {
    if (isList) {
      return `${regions} Region`;
    } else if (isBenchmark) {
      return `${regions?.data?.filter((e: any) => {
        return e.id === Number.parseInt(regionalLevel);
      })[0]?.region_name
        } Region`;
    } else {
      return `${regions?.data?.regions?.filter((e: any) => {
        return e.id === Number.parseInt(regionalLevel);
      })[0]?.name
        } Region`;
    }
  } else {
    return "All Regions";
  }
};

const getDivisionName = (divisionId: any, divisionList: any) => {
  return divisionId !== "" ? `${divisionList?.data?.filter((e: any) => {
    return e.id === Number.parseInt(divisionId);
  })[0]?.name
    } Division` : `All Divisions`;

}
const getTimeName = (timeId: any, timeList: any) => {
  return timeId !== 0 ? `${timeList?.data?.filter((e: any) => {
    return e.id === Number.parseInt(timeId);
  })[0]?.name
    } Period` : 'All Periods';
}

export const getGraphTitle = ({ year, regionId, division, quarter, timeId, regionList, divisionList, isList = false, timeList,
  loginDetails
}: any) => {

  const quarterName = getQuarterName(quarter, year)
  let result = ''
  let isComma = false

  if (loginDetails?.data?.Company?.slug === companySlug?.pep && divisionList) {
    result = result.concat("", getDivisionName(division, divisionList))
    isComma = true
  }

  if (regionId !== null) {
    result = result.concat(`${isComma ? ", " : ""}`, getRegionName(regionList, regionId, isList))
    isComma = true
  }

  if (loginDetails?.data?.Company?.slug === companySlug?.pep && timeList && timeId !== null) {
    result = result.concat(`${isComma ? ", " : ""}`, getTimeName(timeId, timeList))
    isComma = true
  }

  result = result.concat(`${isComma ? ", " : ""}`, `${quarterName} ${year || ''}`);
  return result
}

export const getWeekId = (pId: any, value: any) => pId !== 0 ? "" : value

export const getTimeCheck = (year: string | number, quarter: string | number, period: string | number) => {
  if (period) {
    return { time_id: period }
  } else {
    return {
      quarter: quarter,
      year: year,
    }
  }
}

export const containsB1toB19 = (array: any) => {
  return array?.some((item: string) => {
    const match = item.match(/^B(\d+)$/);
    if (match) {
      const number = parseInt(match[1], 10);
      return number >= 1 && number <= 19;
    }
    return false;
  }) || false;
};

export const containsB20toB100 = (array: any) => {
  return array?.some((item: string) => {
    const match = item.match(/^B(\d+)$/);
    if (match) {
      const number = parseInt(match[1], 10);
      return number >= 20 && number <= 100;
    }
    return false;
  }) || false;
};



export const getBoolean = (isBoolean: boolean) => isBoolean ? 1 : 0 