import { useEffect, useState, useRef } from 'react';
import commonService from "store/commonData/commonService"
import { useAppDispatch, useAppSelector } from 'store/redux.hooks';
import { regionShow, } from 'store/commonData/commonSlice';
import { v4 as uuidv4 } from 'uuid';


const ChatbotController = () => {
  const dispatch = useAppDispatch();
  const divRef = useRef<any>(null);

  const [isChatBoxOpen, setIsChatBoxOpen] = useState(false);
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [messagesList, setMessagesList] = useState<any>([]);
  const [messages, setMessages] = useState('');
  const [selectedType, setSelectedType] = useState('')
  const [selectedRegion, setSelectedRegion] = useState('')
  const { regions, } = useAppSelector((state: any) => state.commonData);

  let regionOption = [{ name: "All", id: "all" }, ...regions?.data?.regions || []]
  useEffect(() => {
    localStorage.removeItem("regionalLevel");
    dispatch(regionShow({ division_id: "" }));
  }, [dispatch,])
  const makeDisable = () => {

    const updatedList = messagesList.map((message: any) => ({
      ...message,
      isDisable: true
    }));

    setMessagesList(updatedList);
  }
  const questionList = [
    {
      name: "Select region",
      list: regionOption,
      id: "selectRegion1",
      handleClick: (res: any, dto: any) => {

        setSelectedRegion(res?.name)
        handleSetMessage({ message: `Great! You have selected ${res?.name} Region. How can I help you?`, isUser: false, isMessages: false })

      }

    }
  ]


  const toggleChatBox = () => {
    setIsChatBoxOpen(!isChatBoxOpen);
  };

  const handleSetMessage = (payload: any) => {
    setMessagesList((prevMessagesList: any) => [
      ...prevMessagesList.map((message: any) => ({
        ...message,
        isDisable: true // Ensure all existing items are set to isDisable: true
      })),
      payload
    ]);
  }


  const cardClick = (cardData: any) => {
    setSelectedType(cardData.type)
    const randomId: any = uuidv4();
    makeDisable()
    if (divRef) {
      divRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
    handleSetMessage({
      meesageID: randomId,
      message: `How can I help you?`,
      isUser: false,
      isMessages: false,
      isOption: questionList?.filter((e: any) => { return e.id === cardData.questionId })?.length > 0,
      optionDto: questionList?.filter((e: any) => { return e.id === cardData.questionId })[0],
      isDisable: false
    })
  }

  const toggleEditVisibility = () => {
    setIsEditVisible(!isEditVisible);
  };

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessages(evt.target?.value);
  };

  const sendMessage = async () => {
    if (divRef) {
      divRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
    if (messages.trim() === '') return;
    setIsLoading(true)
    const payload = { message: messages, isUser: true, isMessages: true };
    handleSetMessage(payload)

    setMessages('');
    commonService.sendMessageBotApi({
      "Type": selectedType,
      "question": messages,
      "Region": selectedRegion
    }).then((res: any) => {
      console.log("resresresres", res)
      handleSetMessage({ message: res, isUser: false, isMessages: true })


      setIsLoading(false)
    }).catch(() => {
      setIsLoading(false)
    })
  }

  const onKeyDownHandler = (e: any) => {
    if (!isLoading) {
      if (e.keyCode === 13) {
        sendMessage();
        e.preventDefault()
 
      }
    }
  };
 
  // Return all the states and functions as an object
  return {
    toggleChatBox,
    isChatBoxOpen,
    isEditVisible,
    toggleEditVisibility,
    sendMessage,
    isLoading,
    messages,
    setMessages,
    handleChange,
    messagesList,
    onKeyDownHandler,
    cardClick,
    selectedType,
    setSelectedRegion,
    divRef
  };
};

export default ChatbotController;
